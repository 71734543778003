/**
 * 订单列表相关API
 */

import request from '@/utils/request'

/**
 * 获取订单列表
 * @param params
 */
export function tradeOrders(params) {
  return request({
    url: '/admin/trade/orders',
    method: 'get',
    loading: false,
    params
  })
}

export function tradeOrdersAgentId(params) {
  return request({
    url: '/admin/trade/orders/agentOrder',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 查询订单出行人及紧急联系人
 * @param order_id
 */
export function tradeOrdersTAndE(order_id) {
  return request({
    url: `/admin/trade/orders/${order_id}/TAndE`,
    method: 'get',
    loading: false
  })
}

/**
 * 查询单个订单明细
 * @param order_sn
 */
export function tradeOrdersOne(order_sn) {
  return request({
    url: `/admin/trade/orders/${order_sn}`,
    method: 'get',
    loading: false
  })
}

/**
 * 导出订单列表
 * @param params
 */
export function tradeOrdersExport(params) {
  return request({
    url: '/admin/trade/orders/export',
    method: 'get',
    params
  })
}

/**
 * 导出分销商订单列表
 * @param params
 */
export function tradeOrdersExportAgent(params) {
  return request({
    url: '/admin/trade/orders/agentOrderExport',
    method: 'get',
    params
  })
}

/**
 * 获取售后详情
 */
export function NewrefundDetail(order_sn) {
  return request({
    url: `admin/after-sales/refund/detail/${order_sn}`,
    method: 'get'
  })
}
/**
 * 查询核销记录
 * @param params
 */
export function getVerifyList(params) {
  return request({
    url: `/admin/trade/orders/verify/list`,
    method: 'get',
    params
  })
}
